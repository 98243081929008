// import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider as StoreProvider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { queryClient } from "@helloaudio/api";
import { store } from "@helloaudio/redux";
import { history } from "@helloaudio/router";
import { AnalyticsProvider } from "@helloaudio/analytics";
import { auth, AuthzeroAdapter } from "@helloaudio/auth";
import {
  unregisterServiceWorker,
  getPreferredColorScheme,
  setPreferredColorScheme,
} from "@helloaudio/utils";

import App from "./App";

/**
 * Bootstrap the application asynchronously
 */
const bootstrap = async () => {
  /**
   * Unregister previously registered service workers
   */
  unregisterServiceWorker();

  /**
   * Set preferred color scheme
   */
  const colorScheme = getPreferredColorScheme();
  setPreferredColorScheme(colorScheme);

  /**
   * Configure auth instance with authzero
   */
  await auth.use(
    new AuthzeroAdapter({
      domain: "auth.helloaudio.fm",
      client_id: process.env.AUTH0_CLIENT_ID,
      redirect_uri: window.location.origin,
      // useRefreshTokens: true,
      // prompt: "none",
      useFormData: true,
      cacheLocation: "localstorage", // TODO: discuss this
    }),
  );

  /**
   * Handle the redirect callback if needed
   */
  await auth.handleRedirectCallback((returnTo) => {
    history.replace(returnTo);
  });

  /**
   * Render the application
   *
   * TODO: strict mode causes weird behavior for some mission critical libraries, like `react-beautiful-dnd` and others.
   * Reenable when refactored.
   */
  const element = document.getElementById("root")!;
  const root = createRoot(element);
  root.render(
    // <StrictMode>
    <AnalyticsProvider
      writeKey={process.env.CUSTOMERIO_API_KEY}
      options={{
        integrations: {
          "Customer.io In-App Plugin": {
            siteId: process.env.CUSTOMERIO_SITE_ID,
          },
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <StoreProvider store={store}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </StoreProvider>
      </QueryClientProvider>
    </AnalyticsProvider>,
    // </StrictMode>
  );
};

bootstrap();
