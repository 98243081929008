// @ts-check

import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";
import { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";

/**
 * @typedef {import('@customerio/cdp-analytics-browser').InitOptions} InitOptions
 * @typedef {import('@customerio/cdp-analytics-browser').AnalyticsBrowser} AnalyticsBrowserType
 */

/** @type {import('react').Context<AnalyticsBrowserType | null>} */
const AnalyticsContext = createContext(/** @type {AnalyticsBrowserType | null} */ (null));

/**
 * @typedef {{
 *   writeKey: string
 *   options?: InitOptions
 * }} AnalyticsProvidersProps
 */

/**
 * Analytics Provider component
 * @param {Object} props - Component props
 * @param {string} props.writeKey - Customer.io write key
 * @param {InitOptions} [props.options] - Customer.io initialization options
 * @param {import('react').ReactNode} props.children - Child components
 * @returns {import('react').ReactElement}
 */
export const AnalyticsProvider = ({ writeKey, options, children }) => {
  const analytics = useMemo(() => {
    console.log("Analytics Options:", options);
    return AnalyticsBrowser.load({ writeKey }, options);
  }, [writeKey, options]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

AnalyticsProvider.propTypes = {
  writeKey: PropTypes.string.isRequired,
  options: PropTypes.shape({
    cookie: PropTypes.object,
    disableAutoISOConversion: PropTypes.bool,
    disableClientPersistence: PropTypes.bool,
    globalAnalyticsKey: PropTypes.string,
    group: PropTypes.object,
    initialPageview: PropTypes.bool,
    integrations: PropTypes.object,
    obfuscate: PropTypes.bool,
    plan: PropTypes.object,
    retryQueue: PropTypes.bool,
    useQueryString: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        aid: PropTypes.instanceOf(RegExp),
        uid: PropTypes.instanceOf(RegExp),
      }),
    ]),
    user: PropTypes.object,
  }),
  children: PropTypes.node.isRequired,
};

/**
 * Hook to access Customer.io analytics
 * @returns {AnalyticsBrowserType} The analytics instance
 * @throws {Error} If used outside of AnalyticsProvider
 */
export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);
  if (!result) {
    throw new Error("useAnalytics must be used within a AnalyticsProvider");
  }

  return result;
};
